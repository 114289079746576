.menuGroup {
    max-width: 960px;
    text-align: center;
    margin: auto;
    padding: 2rem 2rem 5rem 2rem;

    .menu {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3rem;
        overflow-x: hidden;
        padding: 2rem;

        img {
            width: 400px;
            height: auto;
            border-radius: 10px;
            box-shadow: 4px 4px 5px 2px rgba($color: #000000, $alpha: 0.2);
        }
        h4 {
            letter-spacing: 0.5rem;
            line-height: 3rem;

            text-align: right;
            span {
                font-family: "Rubik", sans-serif;
                font-weight: 700;
                font-size: 1.5rem;
                color: #754c29;
            }
        }
    }
}

.button {
    max-width: 960px;
    display: flex;
    margin: auto;
    align-items: center;
    h4 {
        display: flex;
        margin: auto;
        align-items: center;
        color: #314528;
    }

    .iconSize {
        height: 30px;
        width: 30px;
    }
}

@media screen and (max-width: 540px) {
    .menu {
        margin-left: -250px;
    }
}

@media screen and (max-width: 320px) {
    .menu {
        margin-left: -280px;
    }
}
