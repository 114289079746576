.about {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 4rem;
    margin: auto;
    margin-top: 2rem;
    padding: 2rem 2rem 5rem 2rem;
    max-width: 960px;

    h3 {
        margin-bottom: 2rem;
    }

    // line-height: 2rem;

    span {
        background-color: #f5f5dc;
        letter-spacing: normal;
    }

    .imgGroup {
        img {
            max-width: 330px;
            height: auto;
        }
    }
}


@media screen and (max-width: 768px) {
    .about {
        flex-wrap: wrap;
        // height: 100vh;
    }
}

@media screen and (max-width: 330px) {
    .imgGroup {
        img {
            max-width: 240px !important;
        }
    }
}


