@import url("https://fonts.googleapis.com/css2?family=Cagliostro&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Cagliostro", sans-serif;
    letter-spacing: 0.3rem;
    color: #314528;
}

body {
    background-image: url(../assets/food-bg-pattern90.jpg);
    background-repeat: repeat;
}

h1,
h2,
h3,
h4,
h5,
h6,
button,
a {
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    letter-spacing: 0.3rem;
    color: #754C29;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}