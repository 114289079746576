.join {
    max-width: 960px;
    text-align: left;
    margin: auto;
    padding: 5rem auto 5rem auto;
    width: 100%;
}

.textGroup {
    margin: 4rem 0;
    p {
        margin: 2rem 0;
        color: #754C29;
    }
    h3 {
        color: #314528;
    }
}

.container {
    display: flex;
    flex-direction: row;
    // padding: 2rem;
    padding: 5rem 2rem 5rem 2rem;
    max-width: 960px;
    width: 100%;
    margin: auto;
}

@media screen and (max-width: 720px) {
    .container {
        flex-direction: column;
    }
}