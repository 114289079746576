.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5rem;
    width: 100%;
    margin: 0 auto 5rem auto;
    background-color: #F5F5DC;
}

.contact {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    max-width: 960px;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    h3 {
        margin-bottom: 3rem;
        letter-spacing: normal;
    }
    p {
        letter-spacing: normal;
        line-height: 1.3rem;
        &:nth-of-type(2) {
            margin-bottom: 4rem;
        }
    }
}

.icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 2rem auto 5rem auto;
    gap: 2rem;
    padding: 2rem;
    // background-color: #f5f5dc;
    // box-shadow: 2px 2px 5px 2px rgba($color: #000000, $alpha: 0.25);
    h3 {
        color: #314528;
        font-weight: 700;
    }
    div {
        display: flex;
        gap: 2rem;
        img {
            width: 100px;
        }
    }
}

.khcMap {
    text-align: right;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        display: none;
        border: none;
        background-color: #f5f5dc;
        box-shadow: 2px 2px 5px 2px rgba($color: #000000, $alpha: 0.25);
        height: fit-content;
        width: 100%;
        padding: 1rem 2rem 1rem 1rem;
        margin-top: 29rem;
        text-align: right;
        cursor: pointer;
        justify-content: flex-end;
        align-items: center;

        a {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .mapIcon {
            width: 20px;
            height: 20px;
        }
    }
}

@media screen and (max-width: 960px) {
    .contact {
        max-width: 720px;
    }
}

@media screen and (max-width: 720px) {
    .contact {
        flex-direction: column;
    }
}

@media screen and (max-width: 540px) {
    .contact {
        margin: 0;
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .container {
        flex-direction: column;
        padding: 0;
    }
    .khcMap {
        background-image: url("../assets/KHCMap.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 500px;
        box-shadow: 2px 2px 5px 2px rgba($color: #000000, $alpha: 0.25);
        button {
            display: flex;
        }
    }
    .icons {
        background-color: transparent;
        box-shadow: none;
    }
}
