.header {
    display: block;
    max-width: 100vw;
    // border: 1px solid black;
    z-index: 100;
    nav {
        padding: 0 2rem;
        width: 100vw;
        img {
            width: 150px;
            display: none;
        }
        ul {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 3rem;
            padding: 2rem 0;
            transition: ease-out 2s;

            li {
                cursor: pointer;
                a {
                    position: relative;
                    // display: block;
                    color: #314528;
                    transition: 0.5s;

                    &::after {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 3px;
                        top: 100%;
                        left: 0;
                        background: #754C29;
                        transition: transform 0.5s;
                        transform: scaleX(0);
                        transform-origin: right;
                    }

                    &:hover {
                        color: #754C29;
                        &::after {
                            transform: scaleX(1);
                            transform-origin: left;
                        }
                    }
                }
            }
        }
    }
}

.close span {
    &:nth-child(1) {
        transform: rotate(45deg) translate(7px, 7px);
        animation: none;
        width: 80%;
        transition: 0.5s;
    }
    &:nth-child(2) {
        opacity: 0;
    }
    &:nth-child(3) {
        transform: rotate(-45deg) translate(7px, -7px);
        animation: none;
        width: 80%;
        transition: 0.5s;
    }
}

@media screen and (max-width: 960px) {
    .header {
        justify-content: left;
        position: absolute;

        nav {
            display: none;
            position: fixed;
            top: 0;
            ul {
                flex-direction: column;
                gap: 1rem;

                li {
                    padding-left: 0;
                }
            }
        }
        .showmenu {
            display: flex;
            flex-direction: column;
            background-color: white;
            height: 100vh;
            margin: auto;
            align-items: center;

            img {
                margin-top: 2rem;
                display: flex;
            }
        }
    }
}

@media screen and (max-width: 540px) {
    .showmenu {
        align-items: normal !important;
    }
}
