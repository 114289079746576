.footer {
    position: fixed;
    bottom: 0;
    padding: 0.4rem;
    text-align: center;
    width: 100%;

    span {
        font-size: 0.8rem;
        letter-spacing: 0.2rem;
        background-color: #f5f5dc;
        color: #754C29;
    }
}
