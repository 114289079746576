.input {
    margin: 2rem auto 5rem auto;
    padding: 2rem;
    width: 100%;
    max-width: 960px;
    background-color: #F5F5DC;
    border-radius: 10px;
    h4 {
        text-align: center;
        padding: 1rem 0 2rem 0;
    }
}

.input label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-family: "Rubik", sans-serif;
    font-size: 0.8rem;
}

.input input,select {
    font: inherit;
    display: block;
    width: 100%;
    border: 1px solid #ccc;
    padding: 0.4rem;
    margin-bottom: 2rem;
    border-radius: 5px;
}

.input input:focus {
    outline: none;
    border-color: #314528;
}

.input select {
  background-color: white;
}

.input .button {
    font: inherit;
    border: 1px solid #314528;
    background: #314528;
    color: white;
    padding: 0.5rem 2rem;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 1rem;
  }
  
  .button:hover,
  .button:active {
    background: #314528;
    border-color: #314528;
  }
  
  .button:focus {
    outline: none;
  }

  .joinus {
    color: #754C29;
    padding-bottom: 1rem;
    text-align: center;
}

@media screen and (max-width: 540px) {
    .input {
        border-radius: 0;
    }
}