.navButton {
    display: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 100;
    height: 40px;
    width: 40px;

    right: 0;
    top: 0;
    position: fixed;
    margin: 2rem 1rem;
    
    span {
        position: absolute;
        background-color: #314528;
        height: 2px;
        width: 30px;
        right: 0;

        animation: midBar 0.9s infinite;

        &:nth-child(1) {
            margin: -10px 0 0 0;
            animation: topBar 0.8s infinite;
        }

        &:nth-child(3) {
            margin: 10px 0 0 0;
            animation: bottomBar 0.8s infinite;
        }
    }
}

@keyframes midBar {
    0%,
    100% {
        width: 80%;
    }

    50% {
        width: 40%;
    }
}

@keyframes topBar {
    0%,
    100% {
        width: 75%;
    }

    50% {
        width: 58%;
    }
}

@keyframes bottomBar {
    0%,
    100% {
        width: 70%;
    }

    50% {
        width: 60%;
    }
}

.close span {
    &:nth-child(1) {
        transform: rotate(45deg) translate(7px, 7px);
        animation: none;
        width: 80%;
        transition: 0.5s;
    }
    &:nth-child(2) {
        opacity: 0;
    }
    &:nth-child(3) {
        transform: rotate(-45deg) translate(7px, -7px);
        animation: none;
        width: 80%;
        transition: 0.5s;
    }
}


//Navigation Button A
.navButtonA {
    display: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 100;
    height: 40px;
    width: 40px;

    right: 0;
    top: 0;
    position: fixed;
    margin: 2rem 1rem;
    
    span {
        position: absolute;
        background-color: #314528;
        height: 2px;
        width: 30px;
        right: 0;


        &:nth-child(1) {
            margin: -10px 0 0 0;
        }

        &:nth-child(3) {
            margin: 10px 0 0 0;
        }
    }
}

@media screen and (max-width: 960px) {
    .navButton, .navButtonA {
        display: flex;
    }
}
