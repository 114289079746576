.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
  }
  
  .modal {
    position: fixed;
    top: 30vh;
    left: 10%;
    width: 80%;
    z-index: 100;
    overflow: hidden;
  }
  
  .header {
    background: #314528;
    padding: 1rem;
  }
  
  .header h3 {
    margin: 0;
    color: white;
    letter-spacing: normal;
  }
  
  .content {
    padding: 2rem;
    p {
        letter-spacing: normal;
        color: #754C29;
    }
  }
  
  .action {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
  }

  .action {
    .button {
        border: 1px solid #314528;
        background: #314528;
        color: white;
        padding: 0.5rem 1rem;
        cursor: pointer;
        border-radius: 5px;
      }
      
      .button:hover,
      .button:active {
        background: #314528;
        border-color: #314528;
      }
      
      .button:focus {
        outline: none;
      }
  }
  
  @media (min-width: 768px) {
    .modal {
      left: calc(50% - 20rem);
      width: 40rem;
    }
  }