// .gallery {
//     position: relative;
//     height: 100vh;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     // overflow-wrap: break-word;
// }
// .image {
//     max-width: 1000px;
//     width: 70vw;
//     height: 600px;
//     border-radius: 10px;
// }

// .right_arrow {
//     position: absolute;
//     top: 50%;
//     right: 32px;
//     font-size: 3rem;
//     color: #000000;
//     cursor: pointer;
//     user-select: none;
//     z-index: 10;
// }

// .left_arrow {
//     position: absolute;
//     top: 50%;
//     left: 32px;
//     font-size: 3rem;
//     color: #000000;
//     cursor: pointer;
//     user-select: none;
//     z-index: 10;
// }

// .active {
//     display: flex;
// }

// @media screen and (max-width: 768px) {
//     .image {
//         width: 100% !important;
//         height: auto !important;
//         border-radius: none !important;
//     }
// }

// .gallery {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 100%;
//     margin: auto;
// }

// .image {
//     height: 600px;
// }

// .is-visible {
//     img {
//         height: 700px !important;
//     }
// }

// .gallery {
//     .splide__track > ul {
//         margin-top: 1rem;
//     }
// }

// .gallery {
//     visibility: visible !important;
//     margin: 5rem auto;
//     ul {
//         bottom: -2rem;

//         button {
//             background: #000000;
//             .is-active {
//                 background: #000000 !important;
//             }
//         }
//     }
// }

.title {
    // text-align: center;
    max-width: 960px;
    margin: auto;
    padding: 2rem;
}

.image {
    max-height: 800px;
}
.leftrightButton {
    background: black;
}

@media screen and (max-width: 768px) {
    .image {
        max-height: 600px !important;
    }
}
