.bg {
    background-color: #f5f5dc;
}

.team {
    max-width: 960px;
    text-align: center;
    margin: auto;
    padding-bottom: 5rem;
    margin-top: 5rem;

    h3 {
        text-align: left;
        padding: 2rem 0;
    }

    .contentGroup {
        display: flex;
        flex-direction: column;
        text-align: left;

        .imgGroup {
            img {
                width: 50vw;
                border-radius: 10px;
                box-shadow: 4px 4px 5px 2px rgba($color: #000000, $alpha: 0.2);
            }
        }
    }

    .textGroup {
        h3 {
            padding: 2rem 0;
            color: #314528;
        }
        p {
            // font-family: sans-serif;
            line-height: 1.3rem;
            letter-spacing: normal;
            color: #754c29;
        }
    }
}

@media screen and (max-width: 1024px) {
    .team {
        max-width: 768px;
    }
}

@media screen and (max-width: 768px) {
    .team {
        max-width: 540px;
        img {
            width: 100% !important;
        }
    }
}

@media screen and (max-width: 540px) {
    .team {
        h2,
        h3,
        p {
            padding-left: 2rem !important;
            padding-right: 2rem !important;
        }
        img {
            width: 100% !important;
            border-radius: 0 !important;
        }
    }
}
