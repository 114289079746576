.googleMapCode {
    iframe {
        border-radius: 10px;
        box-shadow: 2px 2px 5px 2px rgba($color: #000000, $alpha: 0.25);
    }
}

@media screen and (max-width: 960px) {
    .googleMapCode {
        iframe {
            width: 400px;
            height: 400px;
        }
    }
}

@media screen and (max-width: 460px) {
    .googleMapCode {
        iframe {
            display: none;
            max-width: 400px;
            margin: auto;
            width: 360px;
        }
    }
}

// @media screen and (max-width: 390px) {
//     .googleMapCode {
//         iframe {
//             width: 300px;
//         }
//     }
// }

// @media screen and (max-width: 320px) {
//     .googleMapCode {
//         iframe {
//             width: 260px;
//         }
//     }
// }
